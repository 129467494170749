define("discourse/plugins/discourse-user-notes/discourse-user-notes/connectors/user-card-post-names/show-user-notes-on-card", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/emoji", "discourse-common/helpers/d-icon", "I18n", "discourse/plugins/discourse-user-notes/discourse-user-notes/lib/user-notes", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _dButton, _emoji, _dIcon, _I18n, _userNotes, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor, _descriptor2, _descriptor3, _Class;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_class2 = (_Class = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
    }
    static shouldRender(args1, context1) {
      const {
        siteSettings: siteSettings1,
        currentUser: currentUser1
      } = context1;
      return siteSettings1.user_notes_enabled && currentUser1?.staff;
    }
    get userNotesCount() {
      return parseInt(this.args.outletArgs.user.get("user_notes_count") || this.args.outletArgs.user.get("custom_fields.user_notes_count") || 0, 10);
    }
    showUserNotes() {
      (0, _userNotes.showUserNotes)(this.store, this.args.outletArgs.user.id);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="show-user-notes-on-card">
        {{#if this.userNotesCount}}
          <DButton
            @translatedTitle={{I18n.t
              "user_notes.show"
              count=this.userNotesCount
            }}
            @action={{this.showUserNotes}}
            class="btn-flat"
          >
            {{#if this.siteSettings.enable_emoji}}
              {{emoji "pencil"}}
            {{else}}
              {{icon "sticky-note"}}
            {{/if}}
          </DButton>
        {{/if}}
      </div>
    
  */
  {
    "id": "BZWDPISr",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"show-user-notes-on-card\"],[12],[1,\"\\n\"],[41,[30,0,[\"userNotesCount\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-flat\"]],[[\"@translatedTitle\",\"@action\"],[[28,[32,1,[\"t\"]],[\"user_notes.show\"],[[\"count\"],[[30,0,[\"userNotesCount\"]]]]],[30,0,[\"showUserNotes\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"enable_emoji\"]],[[[1,\"            \"],[1,[28,[32,2],[\"pencil\"],null]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[32,3],[\"sticky-note\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-user-notes/discourse-user-notes/connectors/user-card-post-names/show-user-notes-on-card.js",
    "scope": () => [_dButton.default, _I18n.default, _emoji.default, _dIcon.default],
    "isStrictMode": true
  }), _Class), _Class), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "showUserNotes", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showUserNotes"), _class2.prototype)), _class2);
});